
import Home from "./Home";


const LandingPage = () => {
  return (
    <>
      <Home />
    </>
  )
}
export default LandingPage;